import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { isBefore } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type PublicStevnerSearchFormProps = {
  fromDateISO: string;
  toDateISO: string;
  onSubmit: (searchState: PublicStevnerSearchFormState) => void;
};

export type PublicStevnerSearchFormState = {
  fromDate: Date;
  toDate: Date;
};

const StevnerSearchFormSchema = Yup.object<
  Record<keyof PublicStevnerSearchFormState, Yup.AnySchema>
>({
  fromDate: Yup.date().required('Påkrevd'),
  toDate: Yup.date()
    .required('Påkrevd')
    .test(
      'is-valid-date-range',
      'Til dato må være etter fra dato',
      (_, context) => {
        const { fromDate, toDate } =
          context.parent as PublicStevnerSearchFormState;

        if (fromDate && toDate) {
          return isBefore(fromDate, toDate);
        }

        return true;
      }
    ),
});

export function PublicStevnerSearchForm({
  fromDateISO,
  toDateISO,
  onSubmit,
}: PublicStevnerSearchFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PublicStevnerSearchFormState>({
    resolver: yupResolver(StevnerSearchFormSchema),
    defaultValues: {
      fromDate: new Date(fromDateISO),
      toDate: new Date(toDateISO),
    },
  });

  const handleFormSubmit = (state: PublicStevnerSearchFormState) => {
    onSubmit(state);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex fw-wrap gap-4 align-center">
        <Controller
          name="fromDate"
          control={control}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label="Fra dato"
              inputFormat="dd/MM/yyyy"
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!errors.fromDate}
                  helperText={errors.fromDate?.message}
                />
              )}
            />
          )}
        />

        <Controller
          name="toDate"
          control={control}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label="Til dato"
              inputFormat="dd/MM/yyyy"
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!errors.toDate}
                  helperText={errors.toDate?.message}
                />
              )}
            />
          )}
        />
      </div>

      <Button type="submit" variant="outlined" className="mt-4">
        Søk etter stevner
      </Button>
    </form>
  );
}
