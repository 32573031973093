import * as Yup from 'yup';

export type DommerStevnerFormState = {
  fromDate: Date;
  toDate: Date;
  personId: Nullable<number>;
};

export const dommerStevnerSchema = Yup.object<
  Record<keyof DommerStevnerFormState, Yup.AnySchema>
>({
  fromDate: Yup.date().required('Fra dato er påkrevd').nullable(),
  toDate: Yup.date().required('Til dato er påkrevd').nullable(),
  personId: Yup.number().required('Dommer er påkrevd').nullable(),
});
