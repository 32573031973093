import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Divider, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  showApiErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { Paths } from 'src/Routes';
import { deleteRequest, putRequest } from 'src/ajax/ajax';
import { useStevne } from 'src/ajax/hooks/useStevner';
import { RekorderApi } from 'src/ajax/rekorder.api';
import { ReportsApi } from 'src/ajax/reports.api';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemHeader,
  DescriptionListItemValue,
} from 'src/components/shared/DescriptionList/DescriptionList';
import { Loader } from 'src/components/shared/Loader/Loader';
import { ConfirmModal } from 'src/components/shared/Modal/ConfirmModal';
import { RekordCandidatesModal } from 'src/components/shared/Rekorder/RekordCandidatesModal';
import { mapRekordCandidateToRekordInput } from 'src/components/shared/Rekorder/rekord.mappers';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { LeadType } from 'src/components/shared/Typography/LeadType';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useAuth } from 'src/context/AuthContext';
import { useRemainingPageHeight } from 'src/hooks/useRemainingPageHeight';
import { RekordCandidate } from 'src/models/Rekord';
import { StevneResponse } from 'src/models/Stevne';
import { isNvfAdmin } from 'src/models/User';
import { downloadFileFromResponse } from 'src/utils/file.utils';
import { Page } from '../../shared/Page/Page';
import { Header1 } from '../../shared/Typography/Header1';
import { StevnePulje } from './Stevne/StevnePulje';
import { mapStevneDetailToStevneFormState } from './Stevner.mappers';
import { findStevnePeriodFromStevne } from 'src/utils/stevne';

export const StevneViewPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { id: idParam } = useParams();

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [isRekordModalOpen, setIsRekordModalOpen] = useState(false);
  const [rekordCandidates, setRekordCandidates] = useState<RekordCandidate[]>(
    []
  );

  const { stevne, isLoadingStevne, mutateStevne } = useStevne(
    Number(idParam) ?? undefined
  );

  const [tab, setTab] = useState(0);

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const handleApproveStevne = () => {
    setIsApproveModalOpen(true);
  };

  const handleApproveStevneCancel = () => {
    setIsApproveModalOpen(false);
  };

  const handleApproveStevneConfirm = async () => {
    if (stevne) {
      let stevneFormState = mapStevneDetailToStevneFormState(stevne);
      stevneFormState.godkjent = true;

      const response = await putRequest<StevneResponse>(
        `/stevne/${idParam}/`,
        stevneFormState
      );

      if (response.ok) {
        showSuccessNotification('Stevne godkjent');

        const candidates = response.data.rekordCandidates;
        if (candidates.length > 0) {
          setRekordCandidates(candidates);
          setIsRekordModalOpen(true);
          return;
        }

        mutateStevne();
        navigate(Paths.stevner.base);
      } else {
        showApiErrorNotification(response.error);
      }
    }
  };

  const createRekorder = async (candidates: RekordCandidate[]) => {
    const rekordInputs = candidates.map(mapRekordCandidateToRekordInput);

    const response = await RekorderApi.createRekordBatch(rekordInputs);

    if (response.ok) {
      showSuccessNotification('Rekorder opprettet');
      setIsRekordModalOpen(false);
      mutateStevne();
      navigate(Paths.stevner.base);
    } else {
      showApiErrorNotification(response.error);
    }
  };

  const cancelCreateRekorder = () => {
    setIsRekordModalOpen(false);
    mutateStevne();
    navigate(Paths.stevner.base);
  };

  const handleEditStevne = () => {
    if (stevne) {
      navigate(Paths.stevner.useEdit(stevne?.id));
    }
  };

  const handleDeleteStevne = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteStevneCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteStevneConfirm = async () => {
    if (stevne) {
      const response = await deleteRequest(`stevne/${stevne.id}`);

      if (response.ok) {
        showSuccessNotification('Stevne er slettet');
        navigate(Paths.stevner.base);
      } else {
        showApiErrorNotification(response.error);
      }
    }
  };

  const handleDownloadPdf = async () => {
    if (stevne) {
      setIsDownloadingPdf(true);
      const response = await ReportsApi.downloadStevnePdf(stevne.id);
      setIsDownloadingPdf(false);

      if (response.ok) {
        downloadFileFromResponse(response.axiosResponse);
      }
    }
  };

  const numberOfLifters =
    stevne?.puljeList.reduce((acc, cum) => acc + cum.resultatList.length, 0) ??
    0;
  const datePeriod = stevne ? findStevnePeriodFromStevne(stevne) : '';

  const { ref: tabsRef } = useRemainingPageHeight<HTMLButtonElement>();

  return (
    <Page>
      {isLoadingStevne && (
        <div className="flex flexcol align-center">
          <Loader active className="mb-4" />
          <LeadType>Laster stevne</LeadType>
        </div>
      )}

      {!!stevne && (
        <>
          <Header1>{stevne.stevnetype.navn}</Header1>

          <Divider className="mt-4 mb-6" />

          <div className="flex jc-sb">
            <DescriptionList className="mb-8">
              <DescriptionListItem>
                <DescriptionListItemHeader>Type</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {stevne.stevnetype.navn}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>Sted</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {stevne.sted}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>Klubb</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {stevne.klubb.fulltNavn}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>
                  Antall løftere
                </DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {numberOfLifters}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>Dato</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {datePeriod}
                </DescriptionListItemValue>
              </DescriptionListItem>
            </DescriptionList>

            <div className="ml-auto flex align-start fw-wrap gap-4">
              {isNvfAdmin(user) && (
                <>
                  {!stevne.godkjent && (
                    <Button
                      variant="contained"
                      startIcon={<CheckCircleOutlineIcon />}
                      onClick={handleApproveStevne}
                    >
                      Godkjenn stevne
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={handleEditStevne}
                  >
                    Rediger stevne
                  </Button>

                  {(!stevne.godkjent || user.isSuperuser) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleDeleteStevne}
                    >
                      Slett stevne
                    </Button>
                  )}
                </>
              )}
              {stevne.godkjent && (
                <Button
                  variant="outlined"
                  onClick={handleDownloadPdf}
                  disabled={isDownloadingPdf}
                  sx={{ width: 132 }}
                >
                  {isDownloadingPdf ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Last ned pdf'
                  )}
                </Button>
              )}
            </div>
          </div>

          <Tabs
            ref={tabsRef}
            orientation="horizontal"
            variant="scrollable"
            value={tab}
            onChange={onTabChange}
            className="mb-8"
          >
            {stevne.puljeList.map(pulje => (
              <Tab
                key={pulje.puljeNr}
                label={`Pulje ${pulje.puljeNr}`}
                className="align-start"
              />
            ))}
          </Tabs>

          {stevne.puljeList.map((pulje, index) => (
            <TabPanel
              key={pulje.puljeNr}
              value={tab}
              index={index}
              className="h100"
            >
              <StevnePulje pulje={pulje} />

              {pulje.beskrivelse && (
                <section className="mt-12" style={{ maxWidth: '70ch' }}>
                  <LeadType>Beskrivelse</LeadType>
                  <Paragraph className="mt-4">{pulje.beskrivelse}</Paragraph>
                </section>
              )}
            </TabPanel>
          ))}
        </>
      )}

      <ConfirmModal
        open={isApproveModalOpen}
        onConfirm={handleApproveStevneConfirm}
        onClose={handleApproveStevneCancel}
        title="Godkjenn stevne"
        confirmButtonText="Godkjenn"
      >
        <div>
          Du er i ferd med å godkjenne et
          <b className="mx-1">{stevne?.stevnetype.navn}</b>i
          <b className="mx-1">{stevne?.klubb.navn}</b>
          <br />
          <br />
          Trykk <q>Godkjenn</q> for å fullføre
        </div>
      </ConfirmModal>

      <ConfirmModal
        open={isDeleteModalOpen}
        onConfirm={handleDeleteStevneConfirm}
        onClose={handleDeleteStevneCancel}
        title="Slett stevne"
        confirmButtonText="Slett"
      >
        <div>
          Du er i ferd med å slette et
          <b className="mx-1">{stevne?.stevnetype.navn}</b>i
          <b className="mx-1">{stevne?.klubb.navn}</b>
          <br />
          <br />
          Trykk <q>Slett</q> for å fullføre
        </div>
      </ConfirmModal>

      <RekordCandidatesModal
        open={isRekordModalOpen}
        candidates={rekordCandidates}
        onConfirm={createRekorder}
        onClose={cancelCreateRekorder}
      />
    </Page>
  );
};
